<template>
  <div class="echarts-box">
    <div id="myPie" :style="{ width: '800px', height: '300px' }"></div>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue'
import { getPodCtnr } from '@/api/track'
import * as echarts from 'echarts'

export default {
  name: 'podCtnr',

  setup() {
    const echart = echarts
    const chartData = ref([])
    const getList = async () => {
      chartData.value = await getPodCtnr()
      initChart()
    }
    onMounted(() => {
      getList()
    })

    function initChart() {
      const chart = echart.init(document.getElementById('myPie'), 'light')
      const myRatio = []

      for (let i = 0; i < chartData.value.length; i++) {
        myRatio.push({ value: chartData.value[i].id, name: chartData.value[i].name })
      }
      // 把配置和数据放这里
      chart.setOption({
        title: {
          text: 'Pod Ratio of Total Containers',
          subtext: 'US Only',
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left'
        },
        series: [
          {
            name: 'Pod Ratio',
            type: 'pie',
            radius: '50%',
            data: myRatio,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              },
              scale: true,
              scaleSize: 5,
              label: { show: true },
              labelLine: { show: true }
            }
          }
        ]
      })
      window.onresize = function () {
        // 自适应大小
        chart.resize()
      }
    }

    return {
      chartData, initChart
    }
  }
}
</script>

<style scoped>

</style>
