<template>
  <el-card class="container" :body-style="{ padding: 0 }">
    <el-calendar class="calendar" v-model="currentDate" style="height: 400px">
      <template #dateCell="{ data }">
        <p :class="[data.isSelected ? 'is-selected' : '', calendarItemBgClass(data.day)]">
          <!-- 显示的内容 -->
          {{
            data.day
              .split('-')
              .slice(2)
              .join('')
          }}
          {{ data.isSelected ? '✔️' : '' }}
          <br />
          <!-- 当日金额 -->
          <span class="amount" > {{ getTadayAmount(data.day) }} </span>
        </p>
      </template>
    </el-calendar>
  </el-card>

</template>

<script setup>
import { ref, watch } from 'vue'
import { getChartCalendar } from '@/api/chart'
import emitter from '@/utils/eventHub'
// 选中的日期
const currentDate = ref(new Date())

watch(currentDate, val => {
  emitter.emit('calendarChange', val)
})

const calendarListData = ref([])
const getList = async () => {
  calendarListData.value = await getChartCalendar()
}
getList()

const getTadayAmount = date => {
  for (let i = 0; i < calendarListData.value.length; i++) {
    if (calendarListData.value[i].name === date) {
      return calendarListData.value[i].id
    }
  }
  return 0
}

const calendarItemBgClass = day => {
  const td = convertDateString(new Date())

  if (day > td) {
    return 'profit'
  } else {
    return 'loss'
  }
}

function convertDateString(date) {
  const m = date.getMonth() + 1
  const d = date.getDate()
  const mm = m < 10 ? '-0' + m : '-' + m
  const dd = d < 10 ? '-0' + d : '-' + d
  return date.getFullYear() + mm + dd
}
</script>

<style lang="scss" scoped>
.container {
  height: 516px;
  .calendar {

    ::v-deep .el-calendar__body {
      padding: 0 12px 12px;
      font-size: 14px;
    }
    ::v-deep .el-calendar-day {
      height: 66px !important;
      padding: 0 !important;
      p {
        height: 100%;
        padding: 8px;
      }
      // 金额样式
      .amount {
        font-size: 12px;
      }
      // 正收益
      .profit {
        background-color: #f3fff3;
        span {
          color: #649840;
        }
      }
      // 负收益
      .loss {
        background-color: #ffe7e7;
        span {
          color: #b65d59;
        }
      }

      .is-selected {
        background-color: #d6f2ff;
      }
    }
  }
}
</style>
