<template>
  <div class="echarts-box">
    <div id="myEcharts" :style="{ width: '800px', height: '300px' }"></div>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue'
import { getMonthlyCtnr } from '@/api/track'
import * as echarts from 'echarts'

export default {
  name: 'monthlyCtrn',

  setup() {
    const echart = echarts
    const chartData = ref([])
    const getList = async () => {
      chartData.value = await getMonthlyCtnr()
      initChart()
    }

    onMounted(() => {
      getList()
    })

    function initChart() {
      const chart = echart.init(document.getElementById('myEcharts'), 'light')
      const myMonth = []
      const myCtnr = []
      for (let i = 0; i < chartData.value.length; i++) {
        myMonth.push(chartData.value[i].name)
        myCtnr.push(chartData.value[i].id)
      }
      // 把配置和数据放这里
      chart.setOption({
        title: {
          text: 'Container Arrival By Month',
          subtext: 'US Only',
          left: 'center'
        },
        xAxis: {
          type: 'category',
          data: myMonth
        },
        tooltip: {
          trigger: 'axis'
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: myCtnr,
            type: 'line',
            smooth: true
          }
        ]
      })
    }

    return {
      chartData, initChart
    }
  }
}
</script>

<style scoped>

</style>
