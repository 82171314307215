<template>
  <div>
    <el-row class="mt-20" :gutter="20">
      <el-col :span="12" style="margin-right: 20px">
        <el-card>
          <GMapMap ref="myMapRef" :center="center" :zoom="4" map-type-id="roadmap" style="width: 48vw; height: 51vh" :disableDefaultUI="true">

            <GMapMarker :key="index" v-for="(item,index) in drayPortSum" :position="{lat: item.lat, lng: item.lng}"
                        :icon="require('@/assets/AnchorCubic.png')" :clickable="true" @click="openMarker(item.port)">

              <GMapInfoWindow :closeclick="true"  @closeclick="openMarker(null)" :opened="location===item.port" >
                <div>{{item.port}} : {{ item.total }} containers</div>
              </GMapInfoWindow>
            </GMapMarker>
          </GMapMap>
        </el-card>
      </el-col>
      <el-col :span="11">
        <calendar-vue></calendar-vue>
      </el-col>
  </el-row>
  <el-row class="mt-20" :gutter="20">
      <el-col :span="12">
        <el-card> <monthly-ctrn></monthly-ctrn> </el-card>
      </el-col>
      <el-col style="margin-left: 20px" :span="11">
        <el-card> <pod-ctnr></pod-ctnr> </el-card>
      </el-col>
  </el-row>
 </div>

</template>

<script setup>

import { ref, onActivated } from 'vue'
import { getCtnrPortSum } from '@/api/track'
import MonthlyCtrn from './monthlyCtrn'
import PodCtnr from './podCtrn'
import calendarVue from './calendar/index'
const center = { lat: 39.1, lng: -94.65 }
const location = ref()

function openMarker(pt) {
  location.value = pt
}

const drayPortSum = ref()
const getListData = async () => {
  drayPortSum.value = await getCtnrPortSum({})
}
onActivated(getListData)

</script>

<style lang="scss" scoped>

.mt-20 {
  margin-top: 20px;
}
</style>
